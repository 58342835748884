/*
 * omnico-analyticsintegration
 * Creates an analytics object on the Netstorm object that can be used for analytics tracking.
 */

(function () {
   /*jshint multistr: true */
   'use strict';

   var trackerNames = [];
   var siteSpeedSampleRate = 20;
   var isAdvertisingEnabled = false;
   var eCommerceSiteBasket = null;

   // Internal function to clean up order/lineitem amounts.
   function getCurrencyValue(strValue) { return strValue.replace(/&pound;|£|&#163;/g, ''); }

   // Internal function to correctly format a number to the specified number of places.
   function numberFormat(val, decimalPlaces) {

      var multiplier = Math.pow(10, decimalPlaces);
      return +(Math.round(val * multiplier) / multiplier).toFixed(decimalPlaces);
   }

   // Internal function to check if a specific txn id has already been sent to analytic.
   function checkeCommerceTxnSent(bookingRef) {
      var cookiename = 'analyticsTxnSent_' + bookingRef.replace(/;|\*|_/g, '');
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
         var c = ca[i].trim();
         while (c.charAt(0) === ' ') {
            c = c.substring(1);
         }
         if (c.indexOf(cookiename + '=') !== -1) {
            return true;
         }
      }
      document.cookie = cookiename + '=1; path=/';
      return false;
   }

   // Internal function to extract query string parameters by name.
   function getParameterByName(name, url) {
      if (!url) {
         url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i'),
          results = regex.exec(url);
      if (!results) {
         return null;
      }
      if (!results[2]) {
         return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
   }

   // Function to get the event text for the specified element from the list of attributes else use a default text.
   function getElementEventText(element, attributesToCheck, defaultText) {
      var returnText;
      for (var i = 0; i < attributesToCheck.length; i++) {
         returnText = element.prop(attributesToCheck[i]);
         if ((returnText !== undefined) && (returnText.length > 0)) {
            break;
         }
      }

      if ((returnText === undefined) || (returnText.length === 0)) {
         returnText = defaultText;
      }

      return returnText;
   }

   // Function to initialise Analytics objects 
   function init() {
      /* jshint ignore:start */
      (function (i, s, o, g, r, a, m) {
         i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
         }, i[r].l = 1 * new Date(); a = s.createElement(o),
         m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
      /* jshint ignore:end */

      // Hook up analytics events for all anchors 
      $('a[data-og-eventcategory][href*="lfbook.asp?"]').trigger('click', function () {
         var eventCategory = $(this).prop('data-og-eventcategory');
         var eventLabel = $(this).prop('data-og-eventlabel');

         // If no event category determine one
         if ((eventCategory === undefined) || (eventCategory.length === 0)) {
            eventCategory = 'Hyperlink / Button';
         }

         // If no event label determine one
         if ((eventLabel === undefined) || (eventLabel.length === 0)) {
            eventLabel = $(this).innerText;
            var linkUrl = $(this).prop('href');
            var searchUrl = linkUrl.substr(linkUrl.indexOf('lfbook.asp?') + 10).toLowerCase();
            eventLabel += ' (wci=' + getParameterByName('wci', searchUrl) + ')';
         }

         sendEventDetail(eventCategory, 'Click', eventLabel, false);
      });

      // Hook up analytics events for all form submits 
      $('form[data-og-eventcategory][action*="lfbook.asp?"]').on('submit', function () {
         var eventCategory = getElementEventText($(this), ['data-og-eventcategory', 'id', 'name'], 'Input Form');
         var eventLabel = $(this).prop('data-og-eventlabel');

         // If no event label determine one
         if ((eventLabel === undefined) || (eventLabel.length === 0)) {
            var linkUrl = $(this).prop('action');
            var searchUrl = linkUrl.substr(linkUrl.indexOf('lfbook.asp?') + 10).toLowerCase();
            eventLabel = 'action=' + searchUrl;
         }

         sendEventDetail(eventCategory, 'Form Submit', eventLabel, false);
      });

      // Hook up analytics events for all form submit controls 
      $('form[data-og-eventcategory][action*="lfbook.asp?"] :submit').on('click', function () {
         var eventCategory = $(this).prop('data-og-eventcategory');
         var eventLabel = $(this).prop('data-og-eventlabel');

         // If no event category determine one
         if ((eventCategory === undefined) || (eventCategory.length === 0)) {
            var parentForm = $(this).closest('form');
            eventCategory = getElementEventText(parentForm, ['data-og-eventcategory', 'id', 'name'], 'Input Form');
         }

         // If no event label determine one
         if ((eventLabel === undefined) || (eventLabel.length === 0)) {
            eventLabel = $(this).innerText;
            if (eventLabel.length === 0) {
               eventLabel = getElementEventText($(this), ['id', 'name']);
            }
         }

         sendEventDetail(eventCategory, 'Click', eventLabel, false);
      });

      // Hook up analytics events for all form fields 
      $('form[data-og-eventcategory][action*="lfbook.asp?"] :input').trigger('blur', function () {
         var eventCategory = $(this).prop('data-og-eventcategory');
         var eventLabel = $(this).prop('data-og-eventlabel');
         var eventAction = 'Input Skipped';

         // If no event category determine one
         if ((eventCategory === undefined) || (eventCategory.length === 0)) {
            var parentForm = $(this).closest('form');
            eventCategory = getElementEventText(parentForm, ['data-og-eventcategory', 'id', 'name'], 'Input Form');
         }

         // If no event label determine one
         if ((eventLabel === undefined) || (eventLabel.length === 0)) {
            var label = $('label[for="' + $(this).prop('id') + '"]');
            if (label.length === 0) {
               eventLabel = getElementEventText($(this), ['id', 'name'], 'Form Field');
            }
            else {
               eventLabel = label[0].innerText;
            }
         }

         // Determine if provided / skipped
         if ($(this).val().length > 0) {
            eventAction = 'Input Completed';
         }

         sendEventDetail(eventCategory, eventAction, eventLabel, false);
      });
   }


   // Function to allow trackers to be added to the array for processing.
   function addTracker(newTrackerName, newTrackerKey, isLinkerAllowed, linkedDomains) {
      window.ga('create', newTrackerKey, 'auto', newTrackerName, { 'siteSpeedSampleRate': siteSpeedSampleRate, 'allowLinker': isLinkerAllowed });

      // Setup advertising features if enabled.
      if (isAdvertisingEnabled) {
         window.ga(newTrackerName + '.require', 'displayfeatures');
      }

      // Set up linked domains if required.
      if (isLinkerAllowed) {
         window.ga(newTrackerName + '.require', 'linker');
         window.ga(newTrackerName + '.linker:autoLink', linkedDomains, false, true);
      }

      trackerNames.push(newTrackerName);
   }

   // Function to set the user id for analytics reporting
   function setUserId(userId) {
      trackerNames.forEach(function (tracker) {
         window.ga(tracker + '.set', 'userId', userId);
      });
   }

   // Function to set the site speed sampling rate
   function setSiteSpeedSampleRate(sampleRate) {
      siteSpeedSampleRate = sampleRate;
   }

   // Function to enable/disable Advertising Features
   function enableAdvertisingFeatures(isEnabled) {
      isAdvertisingEnabled = isEnabled;
   }

   // Function to send pageview details
   function sendPageView() {
      var page = getParameterByName('wci', document.location.search.toLowerCase());
      if (page !== undefined) {
         page = '?wci=' + page;
      }
      else {
         page = '';
      }

      var pageDetail = {
         'title': document.title,
         'page': (document.location.pathname + page).toLowerCase()
      };

      trackerNames.forEach(function (tracker) {
         window.ga(tracker + '.send', 'pageview', pageDetail);
      });
   }

   // Function to send an event detail to all the configured trackers.
   function sendEventDetail(eventCategory, eventAction, eventLabel, nonInteractive) {
      var eventDetail = {
         "hitType": 'event',
         "eventCategory": eventCategory,
         "eventAction": eventAction,
         "eventLabel": eventLabel,
         "nonInteraction": nonInteractive
      };

      trackerNames.forEach(function (tracker) {
         window.ga(tracker + '.send', eventDetail);
      });
   }

   // Function to record eBooking error details
   function recordErrorEvent(errorCode, errorDescription, errorCategory, isTechError) {
      sendEventDetail('eBookingsError', errorCategory, ('Error Detail:' + errorCode.toString() + ' ' + errorDescription).trim(), isTechError);
   }

   // Function to send eCommerce transaction data
   function sendeCommerceData(eCommerceOrder) {
      // check if we've already handled this transaction, if so exit early
      if (checkeCommerceTxnSent(eCommerceOrder.id)) {
         return;
      }

      // construct google friendly objects.
      var order = { 'id': eCommerceOrder.id, 'affiliation': 'eBookings', 'revenue': getCurrencyValue(eCommerceOrder.total), 'currency': 'GBP' };
      var lineItems = [];
      var currentLineItem = null;
      var currentCentreCode = '';
      eCommerceSiteBasket = {};
      eCommerceOrder.lineItems.forEach(function (lineItem) {
         if (lineItem.basketType === 'Bonus') {
            // This is a bonus / discount line - update the previous lineitem and its associated summary
            currentLineItem.price = numberFormat((currentLineItem.price + (+getCurrencyValue(lineItem.price))), 2);
            lineItem.unitQuantity = 0;
         }
         else {
            currentLineItem = { 'id': eCommerceOrder.id, 'name': lineItem.productDescription, 'sku': lineItem.productCode, 'category': lineItem.centreDescription, 'price': +getCurrencyValue(lineItem.unitPrice), 'quantity': lineItem.unitQuantity };
            currentCentreCode = lineItem.centreCode.toUpperCase();
            lineItems.push(currentLineItem);
         }

         // Update site summary details.
         if (currentCentreCode === '') {
            currentCentreCode = 'NONE';
         }
         if (eCommerceSiteBasket.hasOwnProperty(currentCentreCode) === false) {
            eCommerceSiteBasket[currentCentreCode] = { lineItems: [lineItem], totalPrice: +getCurrencyValue(lineItem.price), totalQuantity: +lineItem.unitQuantity };
         }
         else {
            eCommerceSiteBasket[currentCentreCode].lineItems.push(lineItem);
            eCommerceSiteBasket[currentCentreCode].totalPrice = numberFormat((eCommerceSiteBasket[currentCentreCode].totalPrice + (+getCurrencyValue(lineItem.price))), 2);
            eCommerceSiteBasket[currentCentreCode].totalQuantity += +lineItem.unitQuantity;
         }
      });

      // Keep a copy of the line item details we build up for analytics posting.
      eCommerceSiteBasket.lineItems = lineItems;

      // Send details to ga
      trackerNames.forEach(function (tracker) {
         window.ga(tracker + '.require', 'ecommerce');
         window.ga(tracker + '.ecommerce:clear');
         window.ga(tracker + '.ecommerce:addTransaction', order);
         lineItems.forEach(function (lineItem) {
            window.ga(tracker + '.ecommerce:addItem', lineItem);
         });
         window.ga(tracker + '.ecommerce:send');
      });
   }

   // Function to get the eCommerce summary data
   function geteCommerceSummary() {
      return JSON.parse(JSON.stringify(eCommerceSiteBasket));
   }

   // Initialise shared Netstorm object if not already initialised.
   if ((window.Netstorm) === undefined) {
      window.Netstorm = {};
   }

   window.Netstorm.analytics = {
      'addTracker': addTracker,
      'enableAdvertisingFeatures': enableAdvertisingFeatures,
      'init': init,
      'recordErrorEvent': recordErrorEvent,
      'setUserId': setUserId,
      'setSiteSpeedSampleRate': setSiteSpeedSampleRate,
      'sendPageView': sendPageView,
      'sendEvent': sendEventDetail,
      'sendeCommerceData': sendeCommerceData,
      'geteCommerceSummary': geteCommerceSummary
   };
})();