/*
*
* BlockBooking Calendar control library
* v1.0 Written by RdB 07/07/2015.
*
*/
(function () {
   /*jshint multistr: true */
   'use strict';

   // Private variables
   var calendarDates = {};
   var calendarOptions = {};

   // Gets the unique Date Indexer for this 
   function getCalendarDateId(date) {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
      var dd = date.getDate().toString();
      return 'd' + yyyy + (mm.length === 2 ? mm : '0' + mm[0]) + (dd.length === 2 ? dd : '0' + dd[0]); // padding
   }

   // Function to handle the display properties for a single date on the date picker.
   function setCalendarDayDisplay(date) {
      // Date in before/after allowed booking range.
      if ((date < calendarOptions.earliestArrivalDate) || (date > calendarOptions.latestDepartureDate)) {
         return { enabled: false, classes: 'calendarDayOutOfRange' };
      }

      // Check the calendar availability
      var currentItemType = 't' + $('#CampsitePitchTypeMandatory').val();
      var calendarDayId = getCalendarDateId(date);
      if ((calendarDates[calendarDayId] !== undefined) && (calendarDates[calendarDayId][currentItemType] !== undefined)) {
         // We have some availability info, lets use it!
         var calendarDayInfo = calendarDates[calendarDayId][currentItemType];
         switch (calendarDayInfo.availabilityType) {
            case 0:
               return { enabled: false, classes: 'og-calendarDayNotAvailable', tooltip: 'Closed' };
            case 1:
               return { enabled: false, classes: 'og-calendarDayOutOfRange' };
            case 2:
               return { enabled: false, classes: 'og-calendarDaySoldOut', tooltip: 'Sold Out' };
            default:
               var arrivalDate = $('#CampsiteArrivalDate').data('DateTimePicker').date();
               var departureDate = $('#CampsiteDepartureDate').data('DateTimePicker').date();
               var rtnClass = 'og-calendarDayAvailable';
               var rtnTooltip = '';
               if ((arrivalDate !== null && departureDate !== null) && (date >= arrivalDate && date <= departureDate)) {
                  rtnClass += ' og-calendarDaySelected';
               }

               if (calendarDayInfo.availableCount <= Math.ceil(calendarDayInfo.totalCount / 4)) {
                  rtnClass += ' og-calendarDayLimited';
                  rtnTooltip = 'Limited Availability (' + calendarDayInfo.availableCount + ')';
               }

               return { enabled: true, classes: rtnClass, tooltip: rtnTooltip };
         }
      }
      else {
         return { enabled: false, classes: 'og-calendarDayUnknownAvailability' };
      }
   }

   // Callback function for processing loaded seat JSON data.
   function processBlockBookingCalendar(data) {
      if ((data === undefined) || (data === null)) {
         return;
      }

      // unpack date data
      for (var dateIndex = 0; dateIndex < data.itemAvailability.length; dateIndex++) {
         var dateData = data.itemAvailability[dateIndex];
         var calendarDay = {
            dateID: getCalendarDateId(new Date(dateData.bookingDate)),
            itemTypeID: 't' + data.blockBookingItemType.toString(),
            generatedOn: new Date(data.generatedOn),
            availabilityType: dateData.availabilityType,
            availableCount: dateData.availableCount,
            totalCount: dateData.totalCount,
            availablePercentage: dateData.availablePercentage
         };

         // Create a new Calendar Date Day entry for this date
         if (calendarDates[calendarDay.dateID] === undefined) {
            calendarDates[calendarDay.dateID] = {};
         }

         // Create/update the BBItemType entry for this item type as required
         if (((calendarDates[calendarDay.dateID][calendarDay.itemTypeID] !== undefined) && (calendarDates[calendarDay.dateID][calendarDay.itemTypeID].generatedOn > calendarDay.generatedOn)) === false) {
            calendarDates[calendarDay.dateID][calendarDay.itemTypeID] = calendarDay;
         }
      }

      // Force an update on the datepickers
      $('#CampsiteArrivalDate, #CampsiteDepartureDate').datetimepicker('update');
   }

   function loadBlockBookingCalendar(calendarOptions, ctrl) {
      // Try load the calendar details for this item type
      $.getJSON(
         '?wci=BlockBookingItemAvailability',
         {
            SELCENTRE: calendarOptions.centreCode,
            BLOCKBOOKINGITEMTYPE: $(ctrl).val()
         },
         function (data) {
            processBlockBookingCalendar(data);
         }
      );
   }

   function init(calendarOptions) {
      // Create the basic date pickers.
      //$("#CampsiteArrivalDate, #CampsiteDepartureDate").datetimepicker('destroy');
      $('#CampsiteArrivalDate, #CampsiteDepartureDate').datetimepicker({
         format: 'DD MMM YYYY',
         minDate: calendarOptions.earliestArrivalDate,
         maxDate: calendarOptions.latestDepartureDate,
         showClear: true,
         keepOpen: false,
         useCurrent: false,
         beforeShowDay: setCalendarDayDisplay
      });

      // Set the correct maxDate for Arrival DatePicker.
      $('#CampsiteArrivalDate').data('DateTimePicker').maxDate(calendarOptions.latestArrivalDate());
      // Set the correct minDate for Departure DatePicker.
      $('#CampsiteDepartureDate').data('DateTimePicker').minDate(calendarOptions.earliestDepartureDate());

      // Hook the onDateChange events for both calendar controls - each one updates the other
      //$('#CampsiteArrivalDate').datetimepicker().on('changeDate', function () {
      //   $("#CampsiteDepartureDate").datepicker('update');
      //});
      //$('#CampsiteDepartureDate').datepicker().on('changeDate', function () {
      //   $("#CampsiteArrivalDate").datepicker('update');
      //});

      // Set the current Arrival Date
      if (calendarOptions.currentArrivalDate !== '') {
         $('#CampsiteArrivalDate').data('DateTimePicker').date(calendarOptions.currentArrivalDate);
      }
      // Set the current Departure Date
      if (calendarOptions.currentDepartureDate !== '') {
         $('#CampsiteDepartureDate').data('DateTimePicker').date(calendarOptions.currentDepartureDate);
      }

      //if ($("#CampsiteArrivalDate").datepicker("getDate") > "")
      //{
      //   var arrivalInst = $("#CampsiteArrivalDate").data("datepicker"),
      //       arrivalDate = $.datepicker.formatDate(arrivalInst.settings.dateFormat ||
      //          $.datepicker._defaults.dateFormat,
      //          $("#CampsiteArrivalDate").datepicker("getDate"),
      //          arrivalInst.settings);
      //   setDatePickerRange(selectedDate, arrivalInst);
      //}
      //if ($("#CampsiteDepartureDate").datepicker("getDate") > "")
      //{
      //   var departureInst = $("#CampsiteDepartureDate").data("datepicker"),
      //       selectedDate = $.datepicker.formatDate(departureInst.settings.dateFormat ||
      //          $.datepicker._defaults.dateFormat,
      //          $("#CampsiteDepartureDate").datepicker("getDate"),
      //          departureInst.settings);
      //   setDatePickerRange(selectedDate, departureInst);
      //}

      // Hook up the change event for the Block Booking Item type (pitch type).
      $('#CampsitePitchTypeMandatory').change(
         function () { loadBlockBookingCalendar(calendarOptions, this); }
      );

      // trigger the initial data load.
      loadBlockBookingCalendar(calendarOptions, $('#CampsitePitchTypeMandatory'));
   }

   // Initialise shared Netstorm object if not already initialised.
   if ((window.Netstorm) === undefined) {
      window.Netstorm = {};
   }

   window.Netstorm.blockBookingCalendar = {
      'init': init
   };
})();
