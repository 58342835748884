/*
 * omnico-browsertabmanager
 *  Sets a unique browser tab identification tag that can be used for reporting back to the server
 */

(function () {
   /*jshint multistr: true */
   'use strict';

   // Define Random String function.
   function randomString(length) {
      var rtnText = '';
      var validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < length; i++)
      {
         rtnText += validChars.charAt(Math.floor(Math.random() * validChars.length));
      }
      return rtnText;
   }

   // Assign a random tab "name"
   if ((window.name === '') || (window.name.toLowerCase() === '_blank'))
   {
      window.name = randomString(16);
   }
})();