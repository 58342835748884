/*
 * omnico-controlhookup
 * Auto hookup datepickers/timepickers/datetimepickers
 */

$(function () {
   'use strict';
   $('.input-group.date').datetimepicker({ format: 'DD MMM YYYY' });

   $('.input-group.time').datetimepicker({
      format: 'HH:mm',
      extraFormats: ['HH:mmA']
   });

   $('.input-group.datetime').datetimepicker({
      format: 'DD MMM YYYY - HH:mm',
      extraFormats: ['DD MMM YYYY - HH:mmA']
   });

   $.each($('.form-control.touchspin'), function () {
      var minValue = $(this).attr('min');
      var maxValue = $(this).attr('max');
      var stepValue = $(this).attr('step');
      var decimals = $(this).data('decimals');

      if (stepValue === 'any') {
         stepValue = 0.1;
      }

      $(this).TouchSpin({
         min: minValue ? minValue : 0,
         max: maxValue ? maxValue : 100,
         step: stepValue ? stepValue : 1,
         decimals: decimals ? decimals : 0
      });

      if (matchMedia('only screen and (max-width: 480px)').matches) {
         $(this).attr('type', 'number');
      }
   });

   function getImageGlyphReplacement(image) {
      var imageName = image.attr('src').substring(7).replace('.gif', '');
      var glyphName = 'glyphicon-';
      var labelText = '&nbsp;';
      switch (imageName) {
         case 'delete':
            glyphName += 'trash';
            labelText += 'Delete';
            break;
         case 'apply':
            glyphName += 'ok';
            labelText += 'Apply';
            break;
         case 'update':
            glyphName += 'repeat';
            labelText += 'Update';
            break;
         case 'print_cancellation':
            glyphName += 'print';
            labelText += 'Print Cancellation';
            break;
         case 'cancel':
            glyphName += 'remove';
            labelText += 'Cancel';
            break;
         case 'payment':
            glyphName += 'shopping-cart';
            labelText += 'Pay';
            break;
         case 'remove':
            glyphName += 'remove';
            labelText += 'Remove';
            break;
         case 'renew':
            glyphName += 'repeat';
            labelText += 'Renew';
            break;
         case 'add':
            glyphName += 'plus';
            labelText += 'Add';
            break;
         case 'info':
            glyphName += 'info-sign';
            labelText += 'Information';
            break;
         case 'info01':
            glyphName += 'info-sign';
            labelText = ''; // No LabelText for this one.
            break;
         case 'subresources':
            glyphName += 'tags';
            labelText += 'Addons';
            break;
         default:
            glyphName = 'og-glyphicon-' + imageName;
            labelText = image.attr('value');
      }
      return { glyphIcon: glyphName, labelText: labelText };
   }

   // Button Image replacements
   $.each($('input[type="image"][src^="images/"]'), function () {
      var glyphReplacement = getImageGlyphReplacement($(this));
      $(this).replaceWith('<button name="' + $(this).attr('name') + '.x" value="' + $(this).attr('value') + '" type="submit" class="btn btn-block btn-default btn-xs"><span class="glyphicon ' + glyphReplacement.glyphIcon + '" aria-hidden="true"></span>' + glyphReplacement.labelText + '</button>');
   });

   // Anchor Image replacements
   $.each($('a > img[src^="images/"]'), function () {
      var anchor = $(this).parent();
      anchor.addClass('btn btn-block btn-default btn-xs');
      var glyphReplacement = getImageGlyphReplacement($(this));
      $(this).replaceWith('<span class="glyphicon ' + glyphReplacement.glyphIcon + '" aria-hidden="true"></span>' + glyphReplacement.labelText);
   });

});