/*
*
* Booking TimeTable control library
* v1.0 Written by RdB 07/07/2015.
*
*/
/*global define:false */
/*global exports:false */
/*global require:false */
/*global jQuery:false */
/*global moment:false */
(function (factory) {
   'use strict';
   if (typeof define === 'function' && define.amd) {
      // AMD is used - Register as an anonymous module.
      define(['jquery', 'moment'], factory);
   } else if (typeof exports === 'object') {
      factory(require('jquery'), require('moment'));
   } else {
      // Neither AMD nor CommonJS used. Use global variables.
      if (typeof jQuery === 'undefined') {
         throw 'omnico-bookingtimetable requires jQuery to be loaded first';
      }
      if (typeof moment === 'undefined') {
         throw 'omnico-bookingtimetable requires Moment.js to be loaded first';
      }
      factory(jQuery, moment);
   }
}(function ($, moment) {
   /*jshint multistr: true */
   'use strict';
   if (!moment) {
      throw new Error('omnico-bookingtimetable requires Moment.js to be loaded first');
   }

   // Private variables
   var timetableData = [];
   var hasSchedule = false;
   var hasAvailablility = false;

   function extractTimeTableDetails(timeTableOptions) {
      var dataRows = $('#og-bookingTimeTable > table > tbody > tr');
      var timetableDates = [];
      var timetableTimes = [];

      // Unpack data rows one at a time (potentially excluding last row as it is a duplicate of first row)
      for (var rowIndex = 0; rowIndex < dataRows.length; rowIndex++) {
         var dataCells = dataRows[rowIndex].cells;
         // Unpack cell content, one at a time, excluding last column as it is a duplicate of first column.
         timetableDates.length = dataCells.length - 1;
         for (var cellIndex = 0; cellIndex < dataCells.length - 1; cellIndex++) {
            var dataCell = dataCells[cellIndex];

            // If we're on the first row unpack the Date labels
            if (rowIndex === 0) {
               if (cellIndex === 0) {
                  // First Cell is just text
                  continue;
               }
               timetableDates[cellIndex - 1] = moment(dataCell.innerHTML.replace('<br>', ' '), timeTableOptions.inputDateFormat);
               if (timetableDates[cellIndex - 1].isBefore(moment().startOf('day'))) {
                  timetableDates[cellIndex - 1].add(1, 'years');
               }
               timetableData[cellIndex - 1] = {
                  date: timetableDates[cellIndex - 1],
                  availableTimes: []
               };
               continue;
            }

            // Any other row, unpack the time column
            if (cellIndex === 0) {
               if ((rowIndex === (dataRows.length - 1)) && (dataCell.innerHTML === 'Time')) {
                  continue;
               }

               // First Cell is timeslot
               timetableTimes.push(moment(dataCell.innerHTML, 'hh:mma'));
               hasSchedule = true;
               continue;
            }
            // Any TH at this point should be ignored
            if (dataCell.tagName === 'TH') {
               continue;
            }

            // Anything else is actual timetable data.
            var availableSpaces = -1;
            var timeSlotHref = '';
            var isBookable = false;

            if ((dataCell.children.length > 0) && (dataCell.children[0].tagName === 'A')) {
               var timeslotLink = dataCell.children[0];

               // Extract the number of spaces if possible.
               var availableTextParts = timeslotLink.text.split(' ');
               if (availableTextParts.length > 1) {
                  availableSpaces = parseInt(availableTextParts[0]);
               }
               timeSlotHref = timeslotLink.href;
               isBookable = true;
               hasAvailablility = true;
            }

            if ($(dataCell).hasClass('og-table-timetable-available')) {
               timetableData[cellIndex - 1].availableTimes.push({
                  text: dataCell.innerHTML,
                  availableCount: availableSpaces,
                  href: timeSlotHref,
                  isBookable: isBookable,
                  time: timetableTimes[rowIndex - 1]
               });
            }
         }
      }
   }

   function generateTimeTable(timetableOptions) {
      // Remove any existing available details.
      $('#og-bookingTimeTableAdv > .list-group > .list-group-item.og-availableDate').remove();

      // Add the available details to the page.
      for (var dateIndex = 0; dateIndex < timetableData.length; dateIndex++) {
         var listGroupSrc = '';
         if (timetableData[dateIndex].availableTimes.length > 0) {
            var timeslotButtons = '';
            var isBookableDay = false;
            for (var timeIndex = 0; timeIndex < timetableData[dateIndex].availableTimes.length; timeIndex++) {
               var timeSlotData = timetableData[dateIndex].availableTimes[timeIndex];
               if (timeSlotData.isBookable) {
                  var tooltipText = 'Available';
                  var badgeType = '';
                  var badgeText = '';
                  if (timeSlotData.availableCount > 0) {
                     badgeText = timeSlotData.availableCount.toString();
                     if (timeSlotData.availableCount === 1) {
                        tooltipText = 'Last One';
                        badgeType = 'badge-danger';
                     }
                     else if (timeSlotData.availableCount < 5) {
                        tooltipText = 'Last Few (' + badgeText + ')';
                        badgeType = 'badge-warning';
                     }
                     else {
                        tooltipText = timeSlotData.availableCount.toString() + ' available';
                        badgeType = 'badge-success';
                     }
                  }
                  timeslotButtons += '<a class="btn btn-default btn-sm og-timetable-timeslotbtn" href="' + timeSlotData.href + '" title="' + tooltipText + '">' + timeSlotData.time.format('HH:mm') + '<span class="pull-right badge ' + badgeType + '">' + badgeText + '</a>';
                  isBookableDay = true;
               }
               else if (timetableOptions.hideUnavailableTimes === false) {
                  timeslotButtons += '<a class="btn btn-default btn-sm og-timetable-timeslotbtn disabled" title="' + timeSlotData.text + '">' + timeSlotData.time.format('HH:mm') + '</a>';
               }
            }

            if (isBookableDay === true || (timetableOptions.hideUnavailableDates === false && timeslotButtons !== '')) {
               var listGroupItemId = 'timetableDay_' + timetableData[dateIndex].date.format('X');
               listGroupSrc = '<div class="list-group-item og-availableDate"><div onclick="" data-toggle="collapse" data-target="#' + listGroupItemId + '" aria-expanded="false">';
               listGroupSrc += '<h4 class="list-group-item-heading og-accordion-toggle">' + timetableData[dateIndex].date.format('dddd D MMMM YYYY') + '</h4></div><div id="' + listGroupItemId + '" class="collapse in">' + timeslotButtons;
               listGroupSrc += '<div class="clearfix"></div></div>';
            }
            else if (timetableOptions.hideUnavailableDates === false) {
               listGroupSrc = '<div class="list-group-item og-unAvailableDate"><h4 class="list-group-item-heading">' + timetableData[dateIndex].date.format('dddd D MMMM YYYY') + ' - ' + timetableOptions.noAvailabilityDayText + '</h4></div>';
            }
            if (listGroupSrc !== '') {
               $('#og-bookingTimeTable').before(listGroupSrc);
            }
         }
      }
   }

   function setApplicableDayButtonStates() {
      var applicableDayFilters = $('.og-daysearch-btn > input[type="checkbox"][checked]');
      if (applicableDayFilters.length === 0) {
         applicableDayFilters = $('.og-daysearch-btn > input[type="checkbox"]');
         applicableDayFilters.prop('checked', 'checked');
      }

      applicableDayFilters.each(function () {
         $(this).parent().addClass('active');
         $(this).parent().prop('aria-pressed', 'true');
      });
   }

   function setSearchFromDateOptions() {
      var firstDate = moment($('#timetableSearchOptions select[name="selectday"] > option:selected').text() + ' ' + $('#timetableSearchOptions select[name="selectmonth"] > option:selected').text() + ' ' + $('#timetableSearchOptions select[name="selectyear"] > option:selected').text(), 'DD MMMM YYYY');

      $('#timeTableSearchStartDateText').html(firstDate.format('dddd, DD MMMM YYYY'));
      $('#timeTableSearchStartDateAmend').trigger('click', function (event) {
         event.preventDefault();

         function showTimeTableSearchDatePicker() {
            var topOffset = $('#searchOptionsPanel').offset().top;
            var windowTop = $(window).scrollTop();
            if ((windowTop > topOffset) || (($(window).height() + windowTop) < ($('#searchOptionsPanel').height() + topOffset))) {
               $("html, body").animate({ scrollTop: topOffset });
            }
            $('#timetableSearchFromDate')[0].focus();
         }

         if ($('#TimeTableSearchDate').is(':visible')) {
            showTimeTableSearchDatePicker();
         }
         else {
            $('#timetableSearchOptions').on('shown.bs.collapse.timeTableSearch', function () {
               $('#timetableSearchOptions').off('shown.bs.collapse.timeTableSearch');
               showTimeTableSearchDatePicker();
            });
            $('#timetableSearchOptions').collapse('show');
         }
      });

      $('#TimeTableSearchDate').datetimepicker({
         format: 'DD MMMM YYYY',
         minDate: moment().startOf('day'),
         defaultDate: firstDate,
         widgetPositioning: {
            horizontal: 'left',
            vertical: 'bottom'
         }
      }).on('dp.change', function (e) {
         $('#timetableSearchOptions select[name="selectday"]').val(e.date.date());
         $('#timetableSearchOptions select[name="selectmonth"]').val(e.date.month() + 1);
         $('#timetableSearchOptions select[name="selectyear"]').val(e.date.year());
      });

   }

   function init(timetableOptions) {
      // Set the button states. If none are checked, all should be checked on the UI.
      setApplicableDayButtonStates();

      // Set the search date picker
      setSearchFromDateOptions();

      // Set the Qty Change handler
      $('#timetableSearchOptions select[name="activitySearchQty"]').on('change', function () {
         var newQty = $(this).val();
         $(this).closest('form').prop('action', function (i, action) {
            if (action.search(/([\?&])(selqty=)[^&#]*/i) !== -1) {
               return action.replace(/([\?&])(selqty=)[^&#]*/i, '$1$2' + newQty);
            }
            else {
               return action + "&selqty=" + newQty;
            }
         });
      });

      // Automatically open Search options if some of the filters are enabled.
      var applicableDayFilters = $('.og-daysearch-btn > input[type="checkbox"][checked]');
      if (applicableDayFilters.length > 0 && applicableDayFilters.length < 7) {
         $('#timetableSearchOptions').collapse('show');
      }

      // Extract the available timeslot data.
      extractTimeTableDetails(timetableOptions);

      // If we did not find a schedule, then show the error and exit.
      if (hasSchedule === false) {
         $('#og-bookingTimeTableNoSpaces').removeClass('hidden');
         return;
      }

      // A schedule must have been found, show search options.
      $('#timetableSearchOptions').parent().removeClass('hidden');

      // If nothing available show error
      if (hasAvailablility === false) {
         $('#og-bookingTimeTableNoSpaces').removeClass('hidden');
      }

      if (timetableOptions.useResponsiveTimetable) {
         // Generate timetable UI
         generateTimeTable(timetableOptions);

         if ($('#og-bookingTimeTableAdv > div.list-group > div.list-group-item').length > 2) {
            $('#og-bookingTimeTableAdv').removeClass('hidden');
         }
      }
      else {
         // Show Dates and Times panel.
         $('#og-bookingTimeTableAdv').removeClass('hidden');
         $('#og-bookingTimeTable').removeClass('hidden');
      }
   }


   // Initialise shared Netstorm object if not already initialised.
   if ((window.Netstorm) === undefined) {
      window.Netstorm = {};
   }

   window.Netstorm.bookingTimeTable = {
      'init': init
   };

}));