/*
*
* Responsive Booking Enquiry control
* v1.0 Written by RdB 03/03/2017.
*
*/
/*global define:false */
/*global exports:false */
/*global require:false */
/*global jQuery:false */
(function (factory) {
   'use strict';
   if (typeof define === 'function' && define.amd) {
      // AMD is used - Register as an anonymous module.
      define(['jquery'], factory);
   } else if (typeof exports === 'object') {
      factory(require('jquery'));
   } else {
      // Neither AMD nor CommonJS used. Use global variables.
      if (typeof jQuery === 'undefined') {
         throw 'omnico-bookingenquiry requires jQuery to be loaded first';
      }
      factory(jQuery);
   }
}(function ($) {
   /*jshint multistr: true */
   'use strict';

   // Private variables
   var bookingData = [];
   var columnHeaders = [];

   function extractBookingDetails() {
      var dataRows = $('#og-bookingEnquiry > table > tbody > tr');

      // If we only have 1 row, then there are no bookings.
      if (dataRows.length === 1) {
         return;
      }

      var updateColumnFound = -1;
      var paidColumnFound = -1;
      var cancelOptionsFound = -1;
      var payOptionsFound = -1;

      // Unpack data rows one at a time
      for (var rowIndex = 0; rowIndex < dataRows.length; rowIndex++) {
         var dataCells = dataRows[rowIndex].cells;

         // Resize columns Headers 
         if (rowIndex === 0) {
            columnHeaders.length = dataCells.length;
            // Unpack column headers, one at a time.
            for (var colIndex = 0; colIndex < dataCells.length; colIndex++) {
               var headerText = dataCells[colIndex].innerHTML;
               columnHeaders[colIndex] = headerText;

               // Every booking enquiry will have at least 6 columns
               // Centre/Activity/Date/Time/Area/Qty
               if (colIndex < 6) {
                  continue;
               }

               // Check for booking options
               switch (headerText)
               {
                  case 'Update':
                     updateColumnFound = colIndex;
                     break;
                  case 'Paid?':
                     paidColumnFound = colIndex;
                     break;
                  case 'Cancel':
                     cancelOptionsFound = colIndex;
                     break;
                  case 'Payment':
                     payOptionsFound = colIndex;
                     break;
               }
            }
         }
         else
         {
            // Create default booking enquiry detail item
            var bookingDetails = { centreName: '', activityDescription: '', bookingDate: '', bookingTime: '',
               area: '', quantity: 0, updateKey: '', isPaid: null, cancelOptionKey: '', cancelOptionAction: '', payOptionKey: '' };

            // Unpack cell content, one at a time.
            for (var cellIndex = 0; cellIndex < dataCells.length; cellIndex++) {
               var dataCell = dataCells[cellIndex];
               switch (cellIndex)
               {
                  case 0: // Centre Name
                     bookingDetails.centreName = dataCell.innerHTML;
                     break;
                  case 1: // Activity Description
                     bookingDetails.activityDescription = dataCell.innerHTML;
                     break;
                  case 2: // Date
                     bookingDetails.bookingDate = dataCell.innerHTML.replace('<br>', ' ');
                     break;
                  case 3: // Time
                     bookingDetails.bookingTime = dataCell.innerHTML.replace('<br>', ' ');
                     break;
                  case 4: // Area
                     bookingDetails.area = dataCell.innerHTML;
                     break;
                  case 5: // Quantity
                     bookingDetails.quantity = parseInt(dataCell.innerHTML);
                     break;
                  case updateColumnFound: // Update 
                     if ((dataCell.children.length > 0) && (dataCell.children[0].tagName === 'INPUT')) {
                        bookingDetails.updateKey = $(dataCell.children[0]).prop('name');
                     }
                     break;
                  case paidColumnFound: // Paid?
                     if (dataCell.innerHTML !== '') {
                        bookingDetails.isPaid = (dataCell.innerHTML === 'Yes');
                     }
                     break;
                  case cancelOptionsFound: // Cancel
                     if ((dataCell.children.length > 0) && (dataCell.children[0].tagName === 'INPUT')) {
                        var inputControl = $(dataCell.children[0]);
                        bookingDetails.cancelOptionKey = inputControl.prop('name');
                        if (inputControl.prop('src').indexOf('print_') === -1) {
                          bookingDetails.cancelOptionAction = 'cancel';
                        }
                        else {
                          bookingDetails.cancelOptionAction = 'print';
                        }
                     }
                     else {
                        bookingDetails.cancelOptionAction = dataCell.innerHTML;
                     }
                     break;
                  case payOptionsFound: // Payment
                     if ((dataCell.children.length > 0) && (dataCell.children[0].tagName === 'INPUT')) {
                        bookingDetails.payOptionKey = $(dataCell.children[0]).prop('name');
                     }
                     else {
                        bookingDetails.payOptionKey = dataCell.innerHTML;
                     }
                     break;
               }
            }

            // Add the booking to the extracted data
            bookingData.push(bookingDetails);
         }
      }
   }

   function stringFormat(b) {
      var a = arguments;
      return b.replace(/(\{\{\d\}\}|\{\d\})/g, function (b) {
         if (b.substring(0, 2) === '{{') {
            return b;
         }
         var c = parseInt(b.match(/\d/)[0]);
         return a[c + 1];
      });
   }

   function getBookingDetailFieldHtml(labelText, fieldValue) {
      return stringFormat('<div class="form-group"><label class="control-label">{0}:</label><p class="form-control-static">{1}</p></div>', labelText, fieldValue);
   }

   function getOptionButtonHtml(buttonKey, labelText, glyphicon) {
      return stringFormat('<button id="{0}" name="{0}" type="submit" class="btn btn-default btn-block" value="{0}"><span class="glyphicon glyphicon-{2}" aria-hidden="true"></span> {1}</button>', buttonKey, labelText, glyphicon);
   }

   function generateBookingEnquiry() {
      // Remove any existing available details.
      $('#og-bookingEnquiryAdv > .list-group > .list-group-item.og-bookingEnquiryItem').remove();

      // Add the booking details to the page.
      for (var bookingIndex = 0; bookingIndex < bookingData.length; bookingIndex++) {
         var listGroupSrc = '<div class="list-group-item og-bookingEnquiryItem">';
         listGroupSrc += stringFormat('<h4 class="list-group-item-heading">{0} - <span class="text-nowrap">{1} {2}</span></h4>', bookingData[bookingIndex].activityDescription, bookingData[bookingIndex].bookingDate, bookingData[bookingIndex].bookingTime);
         listGroupSrc += '<div class="row"><div class="col-sm-8">';
         // Add centre name
         listGroupSrc += getBookingDetailFieldHtml(columnHeaders[0], bookingData[bookingIndex].centreName);
         // Add area
         listGroupSrc += getBookingDetailFieldHtml(columnHeaders[4], bookingData[bookingIndex].area);
         // Add quantity
         if (bookingData[bookingIndex].updateKey === '') {
            // non updateable quantity
            listGroupSrc += getBookingDetailFieldHtml(columnHeaders[5], bookingData[bookingIndex].quantity);
         }
         else {
            // updateable quantity
            listGroupSrc += '<div class="form-group form-group-sm">';
            listGroupSrc += stringFormat('<label class="control-label" for="UnitQuantity{0}">{1}</label>', bookingData[bookingIndex].updateKey, columnHeaders[5]);
            listGroupSrc += '<div class="input-group">';
            listGroupSrc += stringFormat('<input id="UnitQuantity{0}" name="UnitQuantity{0}" type="number" class="form-control" step="1" min="1" value="{1}" />', bookingData[bookingIndex].updateKey, bookingData[bookingIndex].quantity);
            listGroupSrc += '<span class="input-group-btn">';
            listGroupSrc += stringFormat('<button id="{0}" name="{0}" type="submit" class="btn btn-default btn-sm" value="{0}"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Update</button>', bookingData[bookingIndex].updateKey);
            listGroupSrc += '</span></div></div>';
         }
         // Add paid?
         if (bookingData[bookingIndex].isPaid !== null && bookingData[bookingIndex].isPaid) {
            listGroupSrc += getBookingDetailFieldHtml('Paid', '<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
         }

         // Add Cancellation status 
         if (bookingData[bookingIndex].cancelOptionAction !== '' && bookingData[bookingIndex].cancelOptionKey === '') {
            listGroupSrc += getBookingDetailFieldHtml('Cancellation Status', bookingData[bookingIndex].cancelOptionAction);
         }

         listGroupSrc += '</div><div class="col-sm-4">';
         // Add a payment option button if needed
         if (bookingData[bookingIndex].payOptionKey !== '') {
            var payLabelText = 'Pay now';
            var payGlyphicon = 'shopping-cart';
            if (bookingData[bookingIndex].payOptionKey.substr(0,1) === 'R'){
               payLabelText = 'Remove from basket';
               payGlyphicon = 'trash';
            }
            listGroupSrc += getOptionButtonHtml(bookingData[bookingIndex].payOptionKey, payLabelText, payGlyphicon);
         }
         // Add a cancel option button if needed
         if (bookingData[bookingIndex].cancelOptionKey !== '') {
            var cancelLabelText = 'Cancel booking';
            var cancelGlyphicon = 'remove';
            if (bookingData[bookingIndex].cancelOptionAction === 'print') {
               cancelLabelText = 'Print cancellation';
               cancelGlyphicon = 'print';
            }
            listGroupSrc += getOptionButtonHtml(bookingData[bookingIndex].cancelOptionKey, cancelLabelText, cancelGlyphicon);
         }
         listGroupSrc += '</div></div><div class="clearfix"></div></div>';
         $('#og-bookingEnquiry').before(listGroupSrc);
      }
   }

   function init(enquiryOptions) {
      // Extract the booking enquiry data.
      extractBookingDetails();

      // If we did not find any details show error exit.
      if (bookingData.length === 0) {
         $('#og-bookingEnquiryNoBookings').removeClass('hidden');
         return;
      }

      // Some bookings were found, show the booking enquiry detail section
      $('#og-bookingEnquiryAdv').removeClass('hidden');

      // Decide which enquiry to show.
      if (enquiryOptions.useResponsiveLayout) {
         // Generate booking enquiry UI
         generateBookingEnquiry();
      }
      else {
         // Show Booking Enquiry panel.
         $('#og-bookingEnquiry').removeClass('hidden');
      }
   }


   // Initialise shared Netstorm object if not already initialised.
   if ((window.Netstorm) === undefined) {
      window.Netstorm = {};
   }

   window.Netstorm.bookingEnquiry = {
      'init': init
   };

}));